.slow-text {
  font-size: 30px;
  font-weight: bold;
  font-family: cursive;
  color: transparent;
  background-clip: text;
  background-image: linear-gradient(
    45deg,
    rgb(155, 20, 155),
    rgb(151, 142, 158)
  );
}
