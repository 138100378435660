.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.link {
  text-decoration: none;
  color: black;
  padding: 2rem;
  transition: background-color 1s ease;
}

.link:hover {
  background-color: rgba(57, 154, 233, 0.959);
}

.logo {
  padding: 0.5rem;
  border-radius: 50%;
  width: 70px;
}

@media screen and (max-width: 700px) {
  .link {
    padding: 1rem;
  }
}

