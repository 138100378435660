.aboutus-container {
  display: flex;
  width: 100%;
  align-items: center;
}

.aboutus-container img {
  margin-right: 2rem;
}

.aboutus-text {
  min-width: 600px;
  border-radius: 0 3rem 3rem 0;
  padding: 3rem;
  font-size: 20px;
  background-image: linear-gradient(
    45deg,
    rgb(160, 219, 255),
    rgb(42, 165, 248)
  );
}

@media screen and (max-width: 1000px) {
  .aboutus-container {
    display: block;
  }
  .aboutus-text {
    width: 100%;
    padding: 2rem;
    min-width: 300px;
    height: auto;
    font-size: large;
  }
  .aboutus-container img {
    width: 100%;
    margin: auto;
  }
}

.consult-btn{
  margin-top: 0.5rem;
  padding: 0.7rem;
  border-radius: 2rem;
  border: none;
  background-color: rgb(183, 68, 183);
  color: white;
  transition: transform 0.5s linear;
  font-weight: bold;
}
.consult-btn:hover{
  transform: scale(1.1);
}
.consult-btn a{
  text-decoration: none;
  color: white;
}