.surgeries-container {
  display: grid;
}

.cards-container {
  display: grid;
  margin: 3rem 2rem;
  gap: 2rem;
  grid-template-columns: auto auto auto;
}

.card {
  width: 100%;
  height: auto;
  border-radius: 0.7rem;
  text-align: center;
  background-color: rgba(223, 233, 241, 0.856);
  transition: transform 0.5s;
}
.card:hover {
  transform: scale(1.1);
}

.card a {
  text-decoration: none;
  color: rgb(92, 61, 228);
}

.surgery-image {
  width: 100%;
  border-radius: 0.7rem 0.7rem 0 0;
  /* height: 85%; */
}

.card-title {
  text-align: center;
  /* margin: 2rem; */
}

@media screen and (max-width: 700px) {
  .cards-container {
    grid-template-columns: auto auto;
    margin: 3rem 1rem;
    gap: 1rem;
  }
}
