.about-iran-container {
  text-align: center;
  background-image: linear-gradient(
    45deg,
    rgb(160, 219, 255),
    rgb(87, 183, 248)
  );
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.iran-banner-image {
  width: 60%;
  min-width: 700px;
  height: auto;
}

.about-iran-container p,
h1,
h2 {
  text-align: left;
}

.text-content {
  width: 60%;
  min-width: 700px;
  margin: auto;
}
.text-content p {
  font-size: 20px;
}

.iran-images-container {
  display: flex;
  width: 90%;
  gap: 1rem;
  padding-top: 4rem;
  margin: auto;
}

.iran-image-item {
  width: 20%;
  height: auto;
  transition: transform 1s ease-in-out;
  border-radius: 1rem;
}

.active-attraction-image {
  transform: scale(1.3, 1.3);
}

@media screen and (max-width: 700px) {
  .iran-banner-image {
    width: 100%;
    min-width: 400px;
  }
  .text-content {
    width: 100%;
    min-width: 400px;
    padding: 1rem;
  }
  .iran-images-container {
    flex-direction: column;
  }
  .iran-image-item {
    width: 85%;
    margin: auto;
  }
}

