.footer-container {
  background-color: #399ae9f5;
  display: flex;
  flex-direction: column;
}

.footer-details {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem;
}

.footer-company {
  width: 50%;
  padding: 2rem;
}

.footer-contacts {
  width: 50%;
}

.legal-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
  margin-bottom: 1rem;
}

.legal-links a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 650px) {
  .footer-details {
    flex-direction: column;
  }
}
