/* Slider.css */
.carousel-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(1.1);
  transition: opacity 1s ease-in-out, transform 5s ease-in-out;
}

.carousel-slide.active {
  opacity: 1;
  transform: scale(1);
}

.carousel-slide.zoom-out {
  opacity: 1;
  transform: scale(1.1);
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: rgba(76, 101, 245, 0.781);
}
