/* Scroll to Top Button */
.scroll-to-top {
  position: fixed;
  bottom: 0.2rem;
  right: 2rem;
  background-color: rgba(57, 154, 233, 0.959);
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease-out;
  width: 3rem;
  height: 3rem;
  box-shadow: 2px 2px 7px white, -2px -2px 7px white;
}

.scroll-to-top.visible {
  opacity: 0.8;
  transform: translateY(-2rem);
}
